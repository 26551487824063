import React, { useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import LogoIcon from '../assets/Images/royellesLogo.png';
import { Auth } from 'aws-amplify';
import lstrings from '../lstrings.js';
import lockImage from '../assets/svg/ss/unlock.svg';
import axios from 'axios';
import config from "../config.json";
import Snackbar from '@material-ui/core/Snackbar';

const Joi = require('joi');

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {'Copyright © '}
//             <a color="inherit" href="https://someshwara.com/"> Someshwara Software Pvt Ltd</a>
//             {' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        marginTop: -8
    },
    header: {
        position: 'absolute',
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px'
    }
}));

export default function SignInSide(props) {
    const classes = useStyles();
    const email = useRef(null);
    const password = useRef(null);
    const [userNameError, setUserNameError] = React.useState(null);
    const [passwordError, setPasswordError] = React.useState(null);
    const [rememberMeChecked, setRememberMeChecked] = React.useState(true);
    const [contactingServer, setContactingServer] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);

    // const theme = useTheme();
    // const [language, setLanguage] = React.useState('en');
    // const themeChanged = (event) => {
    //     setLanguage(event.target.value);
    //     let direction = 'ltr';
    //     let lang = event.target.value;
    //     if (event.target.value === 'ar') {
    //         direction = 'rtl';
    //         theme.direction = 'rtl';
    //         lang = 'ar';
    //     }
    //     else if (event.target.value === 'en') {
    //         direction = 'ltr';
    //         theme.direction = 'ltr';
    //         lang = 'en';
    //     }
    //     else {
    //         direction = 'ltr';
    //         theme.direction = 'ltr';
    //         lang = 'en';
    //     }

    //     document.getElementsByTagName('html')[0].setAttribute("dir", direction);
    //     lstrings.setLanguage(lang);
    // };

    useEffect(() => {
        const remembered = window.localStorage.getItem("RememberSignIn");
        if (remembered && remembered === "true") {
            setRememberMeChecked(true);
            const rememberedUsername = window.localStorage.getItem("username");
            const rememberedPassword = window.localStorage.getItem("password");
            email.current.value = rememberedUsername;
            password.current.value = rememberedPassword;
        }
        else {
            setRememberMeChecked(false);
        }
    }, []);

    const validateData = () => {
        const schema = Joi.object({
            email: Joi.string().min(5).max(100).required(),
            password: Joi.string().min(6).pattern(new RegExp('^[a-zA-Z0-9!@#$%^&*-]{3,30}$'))
        });
        const { error } = schema.validate({ email: email.current.value.trim(), password: password.current.value.trim() }, { abortEarly: false });
        const allerrors = {};
        if (error) {
            for (let item of error.details)
                allerrors[item.path[0]] = item.message;
        }

        return allerrors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setUserNameError(null);
        setPasswordError(null);

        const errors = validateData();

        let errorOccured = false;
        if (errors["email"]) {
            setUserNameError(errors["email"]);
            errorOccured = true;
        }
        if (errors["password"]) {
            setPasswordError(errors["password"]);
            errorOccured = true;
        }

        if (errorOccured)
            return;

        signIn();
    }

    const signIn = async () => {
        setContactingServer(true);
        try {
            let url = config["baseurl"] + "/admin-signin";

            window.localStorage.setItem("token", null);

            let postObj = {};
            postObj["username"] = email.current.value.trim();
            postObj["password"] = password.current.value.trim();
            postObj["device_token"] = "Web";
            delete axios.defaults.headers.common['token'];
            console.log("postObj: ", postObj);
            const response = await axios.post(url, postObj);

            console.log(response);
            console.log(response.data.token);
            console.log(response.data.name);
            window.localStorage.setItem("token", response.data["token"]);

            props.onAuthSuccess();
        } catch (error) {
            console.log("signin 4");
            setAlertOpen(true);
            if (error.response)
                setErrorMsg(error.response.data["error"]);
            else
                setErrorMsg(error.message);

            if (error.code === "UserNotConfirmedException") {
                try {
                    await Auth.resendSignUp(email.current.value.trim());
                }
                catch (error2) {

                }
                setContactingServer(false);
                props.history.push('./verifyemail');
            }
            else {

                setContactingServer(false);
            }
            return;
        }
    }

    const handleChange = (event) => {
        window.localStorage.setItem("RememberSignIn", (event.target.checked ? "true" : "false"));
        window.localStorage.setItem("username", (event.target.checked ? email.current.value.trim() : ""));
        window.localStorage.setItem("password", (event.target.checked ? password.current.value.trim() : ""));

        setRememberMeChecked(event.target.checked);
    };

    const handleClose = () => {
        setAlertOpen(false);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img src={LogoIcon} width='300px' alt="" />
                    <img src={lockImage} width='30px' height='30px' alt=""></img>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            inputRef={email}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        {userNameError && <Alert className={classes.alert} severity="error"> {userNameError} </Alert>}
                        <TextField
                            inputRef={password}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {passwordError && <Alert className={classes.alert} severity="error"> {passwordError} </Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={contactingServer}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/forgotpassword" > Forgot password? </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Grid >

            { <Snackbar open={alertOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMsg}
                </Alert>
            </Snackbar>}

        </Grid >
    );
}